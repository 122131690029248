<template>
  <v-col
    cols="12"
    v-if="!isEnd"
    class="answer-row d-flex justify-center"
    :class="started ? 'top-margin' : ''"
  >
    <RepeatButton
      v-if="started"
      :isDisabled="showAnswer || disableButtons"
      @click.native="repeatQuestion"
    />

    <StartTestButton
      v-if="!started"
      @start="
        started = true;
        playIntro();
      "
    />
    <template v-if="started">
      <LettersTestAnswerOption
        v-for="(option, idx) in currentQuestion.options"
        :key="`option-${idx}`"
        :letter="option"
        @click.native="answerQuestion(option)"
        :disabled="disableButtons"
        :isTutorial="true"
        :class="{
          disabledButton: disableButtons,
          solution: option === currentQuestion.solution,
          wrongAnswer: option != currentQuestion.solution,
          highlight: option === currentQuestion.solution && showAnswer,
          darken: option != currentQuestion.solution && showAnswer,
          disableMultipleClick:
            preventDoubleClick &&
            !(option === currentQuestion.solution && enableSolution),
        }"
      />
    </template>
    <audio :src="audioSrc" ref="currentAudio" @ended="playSound"></audio>
  </v-col>
</template>

<script lang="ts">
import LettersModule from '@/store/modules/LettersModule';
import UserModule from '@/store/modules/UserModule';
import { LettersQuestionType } from '@/types';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RepeatButton from '../../base/RepeatButton.vue';
import StartTestButton from '../../base/StartTestButton.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import LettersTestAnswerOption from './LettersTestAnswerOption.vue';
import TutorialMixin from '../TutorialMixin';

@Component({
  components: {
    LettersTestAnswerOption,
    TutorialEndEmoji,
    RepeatButton,
    StartTestButton,
  },
})
export default class LettersTutorialContainer extends TutorialMixin {
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  idx = 0;
  answered = 0;
  totalQuestions = this.lettersModule.tutorialData.length;
  disableButtons = true;
  preventDoubleClick = false;
  enableSolution = false;
  showAnswer = false;
  started = false;
  currentSound: HTMLAudioElement | null = null;
  @Ref() currentAudio!: HTMLAudioElement;
  tutorialEndSound?: HTMLAudioElement;
  audioSrc = '/audio/click.mp3';
  answerPlaying = false;
  canPlayTimeout = 0;

  get audios() {
    return {
      intro: '/audio/tutorials/u1/intro.mp3',
      listen: '/audio/tutorials/u1/HELP3839.mp3',
      clickCorrectLetter: '/audio/tutorials/u1/HELP274.mp3',
      clickOrangeLetter: '/audio/tutorials/u1/HELP264.mp3',
      wellDone: '/audio/tutorials/u1/HELP290.mp3',
      super: '/audio/tutorials/u1/HELP3833.mp3',
      wrong: '/audio/tutorials/u1/HELP3834.mp3',
      rightLetterHint: '/audio/tutorials/u1/HELP3835.mp3',
      readyGo: '/audio/tutorials/u1/HELP3836.mp3',
      letterR: `/audio/letters/r.mp3`,
      letterB: `/audio/letters/b.mp3`,
      default: '/audio/click.mp3',
    };
  }

  get currentQuestion(): LettersQuestionType {
    return this.lettersModule.tutorialQuestions[this.idx];
  }

  get isEnd(): boolean {
    return this.answered === this.totalQuestions;
  }

  playNextQuestion(): void {
    if (!this.isEnd && this.idx != 0) {
      this.preventDoubleClick = false;
      this.disableButtons = true;
      this.enableSolution = false;

      this.audioSrc = `/audio/letters/${this.currentQuestion.solution}.mp3`;

      setTimeout(async () => {
        this.playSound();
      }, 600);
    }
  }

  repeatQuestion(): void {
    this.userModule.stopActiveAudio();
    //reset to disabled
    this.preventDoubleClick = false;
    this.disableButtons = true;
    this.enableSolution = false;
    //reset and repeat whole intro
    this.idx = 0;
    this.answered = 0;
    // INFO added
    this.audioSrc = this.audios.default;
    this.playIntro();
  }

  playIntro(): void {
    if (!this.tutorialEndSound) {
      this.tutorialEndSound = new Audio(this.audios.readyGo);
    }
    this.playSound();
  }

  async wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async playCorrectAnswer(): Promise<void> {
    this.audioSrc = this.audios.super;

    if (this.showAnswer) {
      this.currentAudio.volume = 0.0;
    }
    this.playSound();
    this.showAnswer = false;
    // INFO wait second before going to tutorial end
    await this.wait(1200);
    this.idx++;
    this.answered++;

    if (this.isEnd) {
      if (this.isTutorialOnlyVersion) {
        this.redirectOnTutorialOnly();
      } else {
        this.lettersModule.finishedTutorial();
        // INFO play end when going to test container
        setTimeout(() => {
          this.userModule.setActiveAudio(this.tutorialEndSound!);
          this.tutorialEndSound!.play();
        }, 2000);
      }
    } else {
      setTimeout(() => {
        if (this.currentAudio) {
          this.currentAudio.volume = 1.0;
        }
        this.playNextQuestion();
      }, 800);
    }
  }

  addEventListeners(): void {
    this.currentAudio?.addEventListener('canplay', this.onCanPlay);
    this.currentAudio?.addEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
  }

  playWrongAnswer(): void {
    setTimeout(async () => {
      this.audioSrc = this.audios.wrong;
      this.addEventListeners();
    }, 1000);
  }

  onCanPlay() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout = window.setTimeout(
      this.onCanPlayThrough.bind(this),
      6 * 1000,
    );
  }

  onCanPlayThrough() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout && (this.canPlayTimeout = 0);
    this.currentAudio?.removeEventListener('canplay', this.onCanPlay);
    this.currentAudio?.removeEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
    this.userModule.setActiveAudio(this.currentAudio);
    this.currentAudio?.play();
  }

  playSound() {
    // case on audio ended
    switch (this.audioSrc) {
      case this.audios.default:
        this.audioSrc = this.audios.intro;
        this.addEventListeners();
        break;
      case this.audios.intro:
        this.audioSrc = this.audios.listen;
        this.addEventListeners();
        break;
      case this.audios.listen:
        this.audioSrc = this.audios.letterR;
        this.addEventListeners();
        break;
      case this.audios.letterR:
        this.audioSrc = this.audios.clickCorrectLetter;
        this.addEventListeners();
        break;
      case this.audios.letterB:
        // enable answer call again
        this.answerPlaying = false;
        this.disableButtons = false;
        break;
      case this.audios.clickCorrectLetter:
        this.disableButtons = false;
        break;
      case this.audios.super:
        // enable answer call again
        this.answerPlaying = false;
        this.addEventListeners();
        // 1 when ended enable buttons
        this.disableButtons = true;
        break;
      case this.audios.wrong:
        this.audioSrc = this.audios.rightLetterHint;
        this.addEventListeners();
        //highlight correct answer and darken wrong answers
        this.showAnswer = true;
        break;
      case this.audios.rightLetterHint:
        this.audioSrc = this.audios.clickOrangeLetter;
        this.addEventListeners();
        break;
      case this.audios.clickOrangeLetter:
        // enable answer call again
        this.answerPlaying = false;
        // make only solution clickable
        this.enableSolution = true;
        this.disableButtons = false;
        break;
      default:
        break;
    }
  }

  answerQuestion(answer: string): void {
    // prevent double click
    if (this.answerPlaying) return;
    this.answerPlaying = true;
    this.preventDoubleClick = true;
    this.enableSolution = false;
    this.disableButtons = true;

    if (answer === this.currentQuestion.solution) {
      setTimeout(async () => {
        await this.playCorrectAnswer();
      }, 1000);
    } else {
      this.playWrongAnswer();
    }
  }

  beforeDestroy() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
  }
}
</script>

<style lang="scss" scoped>
.answer-row {
  width: 95%;
  // margin-top: 10%;
}

.top-margin {
  // margin-top: 8%;
  margin-top: 0%;
}
</style>
