<template>
  <v-row class="answer-row justify-center">
    <TutorialEndEmoji
      v-if="!started"
      :disabled="disableButtons"
      :text="$t('tests.go-start')"
      @start="
        started = true;
        startInitial();
      "
    />
    <template v-if="started && !isEnd">
      <LettersTestAnswerOption
        v-for="(option, idx) in currentQuestion.options"
        :key="`option-${idx}`"
        :letter="option"
        @click.native="answerQuestion(option)"
        :disabled="disableButtons"
        :class="{
          disabledLetter: disableButtons,
          disabledButton: disableButtons,
          solution: option === currentQuestion.solution,
        }"
      />

      <audio
        :data-solution="currentQuestion.solution"
        :src="`/audio/letters/${currentQuestion.solution}.mp3`"
        ref="currentAudio"
        @ended="audioEnd"
      ></audio>
    </template>
    <audio :src="'/audio/toll.mp3'" ref="testEndSound"></audio>
    <template v-if="isEnd && started">
      <TestEndEmoji
        :saving="loadingSave"
        :save-success="saveSuccess"
        :reached-max-retries="reachedMaxRetries"
        :module="lettersModule"
      />
    </template>
  </v-row>
</template>

<script lang="ts">
import LettersModule from '@/store/modules/LettersModule';
import UserModule from '@/store/modules/UserModule';
import { LettersQuestionType } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RetryMixin from '../RetryMixin';
import LettersTestAnswerOption from './LettersTestAnswerOption.vue';

@Component({
  components: { LettersTestAnswerOption, TestEndEmoji, TutorialEndEmoji },
})
export default class LettersTestContainer extends RetryMixin {
  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  start = 0;
  stop = 0;
  disableButtons = true;
  showTutorialEnd = true;
  @Ref() currentAudio!: HTMLAudioElement;
  @Ref() testEndSound!: HTMLAudioElement;
  answerLocked = false;

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 7500);
  }

  startPerformance(): void {
    this.start = performance.now();
  }

  get currentQuestion(): LettersQuestionType {
    return this.lettersModule.currentQuestion;
  }

  get allAquestions(): LettersQuestionType[] {
    return this.lettersModule.testData;
  }

  get isStart(): boolean {
    return this.lettersModule.result?.questionsAnswered === 0;
  }

  get isEnd(): boolean {
    return (
      this.lettersModule.overallQuestions ===
      this.lettersModule.result?.questionsAnswered
    );
  }

  audioEnd(): void {
    this.disableButtons = false;
    // INFO open answer options
    this.answerLocked = false;
    this.start = performance.now();
  }

  async startTimer(): Promise<void> {
    this.userModule.setActiveAudio(this.currentAudio);
    this.currentAudio.play();
  }

  startInitial(): void {
    setTimeout(async () => {
      this.disableButtons = true;
      await this.startTimer();
    }, 1000);
  }

  async updated(): Promise<void> {
    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.lettersModule.saveStatus(
          this.userModule.currentUser._id,
        );

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.lettersModule.finishedAndSavedTest();
        }
      } catch (error: any) {
        this.lettersModule.resetDone();
        console.error(
          '[LETTERS] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  playNextQuestion(): void {
    setTimeout(async () => {
      if (!this.isEnd) {
        this.startTimer();
      }
    }, 500);
  }

  answerQuestion(answer: string): void {
    if (this.answerLocked) return;
    this.answerLocked = true;
    this.stop = performance.now();
    const solution = this.currentQuestion.solution;
    // time in ms =>
    const time = this.stop - this.start;
    this.disableButtons = true;
    setTimeout(() => {
      this.lettersModule.addAnswer({
        solution: solution,
        answer: answer,
        time: Math.round(time),
        isCorrect: answer === solution,
      });
      if (this.isEnd) {
        this.userModule.setActiveAudio(this.testEndSound);
        this.testEndSound.play();
      } else {
        this.playNextQuestion();
      }
    }, 500);
  }
}
</script>

<style lang="scss">
.answer-row {
  width: 95%;
  // margin-top: 12%;
}

.disabledLetter {
  box-shadow: 0px transparent !important;
  cursor: default !important;
  pointer-events: none;
  color: grey;
}

@media (hover: hover) and (pointer: fine) {
  .disabledLetter:hover {
    border: 1px solid transparent !important;
    box-shadow: 0px transparent !important;
  }
}
</style>
