<template>
  <v-img
    :src="image"
    contain
    eager
    max-height="55"
    max-width="55"
    class="mr-3"
  />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class LeoImage extends Vue {
  get image(): string {
    return require('@/assets/images/lion_small.png');
  }
}
</script>

<style scoped lang="scss"></style>
