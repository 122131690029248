<template>
  <v-col
    v-if="!isEnd"
    cols="11"
    sm="10"
    md="8"
    lg="6"
    xl="6"
    class="riddle__answer d-flex flex-column justify-start align-center"
  >
    <TutorialEndEmoji
      v-if="!started"
      :text="$t('tests.go-start')"
      :disabled="disableButtons"
      @start="
        started = true;
        startInitial();
      "
    />
    <template v-if="started">
      <RiddleQuestion :question="currentQuestion.question" />
      <v-row class="riddle__options d-flex flex-nowrap justify-start pt-0">
        <v-col
          cols="7"
          sm="6"
          md="6"
          lg="6"
          xl="6"
          class="d-flex flex-column align-center justify-start flex-wrap"
        >
          <RiddleAnswerOption
            v-for="(option, idx) in currentQuestion.options"
            :key="`option-${idx}`"
            :word="option"
            @click.native="addToAnswers(idx)"
            :class="{ chosen: isChosen(idx) }"
          />
        </v-col>
      </v-row>
      <v-row class="btn-row justify-center align-center">
        <ArrowButton @click.native="answerQuestion" />
      </v-row>
    </template>
  </v-col>
  <TestEndEmoji
    v-else
    :saving="loadingSave"
    :save-success="saveSuccess"
    :reached-max-retries="reachedMaxRetries"
    :module="riddleModule"
  />
</template>

<script lang="ts">
import RiddleModule from '@/store/modules/RiddleModule';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import { RiddleQuestionType } from '@/types';
import { clickSound, getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, Form } from '../../../api/types';
import ArrowButton from '../../base/ArrowButton.vue';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import RetryMixin from '../RetryMixin';
import RiddleAnswerOption from './RiddleAnswerOption.vue';
import RiddleQuestion from './RiddleQuestion.vue';

@Component({
  components: {
    RiddleQuestion,
    TestEndEmoji,
    ArrowButton,
    RiddleAnswerOption,
    TutorialEndEmoji,
  },
})
export default class RiddleContainer extends RetryMixin {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  answers: number[] = [];
  start = 0;
  stop = 0;
  disableButtons = true;

  get currentQuestion(): RiddleQuestionType {
    return this.riddleModule.currentQuestion;
  }

  get isEnd(): boolean {
    return (
      this.riddleModule.overallQuestions ===
      this.riddleModule.result.questionsAnswered
    );
  }

  get isTherapyClient(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 6500);
  }

  startInitial(): void {
    this.start = performance.now();
  }

  async updated(): Promise<void> {
    this.start = performance.now();

    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.riddleModule.saveStatus(
          this.userModule.currentUser._id,
        );
        //INFO set finished form
        const userId = this.userModule.currentUser._id;
        const updateData =
          this.riddleModule.chosenForm === Form.a
            ? { doneA: true }
            : { doneB: true };
        await this.userModule.updateUser({
          id: userId,
          data: updateData,
        });

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.riddleModule.finishedAndSavedTest();

          if (this.isTherapyClient) {
            try {
              await this.schoolModule.decreaseValidTests(
                this.schoolModule.slug,
              );
              await this.schoolModule.getBasicSchoolInfo(
                this.schoolModule.slug,
              );
            } catch (error) {
              console.log('could not fetch school info', error);
            }
          }
          // INFO after "gratuliere alles geschafft" going to congratulations
          const congratulations = new Audio('/audio/gratuliere_alles.mp3');
          const superAudio = new Audio('/audio/tutorials/u1/HELP3833.mp3');

          congratulations.addEventListener(
            'ended',
            () => {
              setTimeout(async () => {
                this.riddleModule.haveShownCongrats(this.$route.params.form);
                this.$router.replace({
                  name: 'congratulations',
                  params: { schoolName: getSchoolPrefix() },
                });
                setTimeout(() => superAudio.play(), 1000);
              }, 2300);
            },
            { once: true },
          );
          congratulations.play();
        }
      } catch (error: any) {
        this.riddleModule.resetDone();
        console.error(
          '[RIDDLE] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  addToAnswers(answerIdx: number): void {
    clickSound.play();
    if (this.answers.includes(answerIdx)) {
      const idx = this.answers.indexOf(answerIdx);
      this.answers.splice(idx, 1);
    } else {
      this.answers.push(answerIdx);
    }
    this.answers.sort();
  }

  isChosen(idx: number): boolean {
    return this.answers.includes(idx);
  }

  answerQuestion(): void {
    //if nothing selected don't check
    if (this.answers.length === 0) {
      return;
    }
    this.stop = performance.now();
    const time = this.stop - this.start;

    //CHECK IF CORRECTLY ANSWERED
    const isCorrect = this.checkAnswer();
    const answers: string[] = this.answers.map(
      (idx) => this.currentQuestion.options[idx],
    );

    this.riddleModule.addAnswer({
      question: this.currentQuestion.question,
      answers: answers,
      time: Math.round(time),
      isCorrect: isCorrect,
    });
    this.answers = [];
  }

  checkAnswer(): boolean {
    const solution = this.currentQuestion.solution;
    return (
      solution.length === this.answers.length &&
      solution.every((answer, idx) => answer === this.answers[idx])
    );
  }
}
</script>

<style scoped lang="scss">
.riddle {
  &__answer {
    width: 100%;
    margin-bottom: auto;
  }

  &__options {
    max-height: calc(6 *#{$riddle_xs});
    width: 75%;
    align-self: start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }
}

.btn-row {
  width: 20%;
}

@media screen and (max-width: 800px) {
  .riddle {
    &__answer {
      width: 85%;
    }

    &__question {
      width: 85%;
    }
  }
}

@media screen and (min-width: 600px) {
  .riddle {
    &__options {
      max-height: calc(6 * #{$riddle_sm}) !important;
    }
  }
}

@media screen and (min-width: 960px) {
  .riddle {
    &__options {
      max-height: calc(6 * #{$riddle_md}) !important;
    }
  }
}

@media screen and (min-width: 1264px) {
  .riddle {
    &__options {
      max-height: calc(6 * #{$riddle_lg}) !important;
    }
  }
}

@media screen and (min-width: 1904px) {
  .riddle {
    &__options {
      max-height: calc(6 * #{$riddle_lg}) !important;
    }
  }
}

.chosen {
  border: 5px orange solid !important;
}

//question

//options

//btn-row

.top-margin {
  margin-top: 6%;
}
</style>
