<template>
  <v-btn
    class="words-answer my-2 my-sm-2 my-lg-3 py-3 text-h6 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 unselectable pt-4 norddruck"
    elevation="8"
    raised
    @click.native="showBorder"
    :class="{ showOrangeBorder: showOrangeBorder }"
  >
    {{ word }}
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class FindWordsAnswerOption extends Vue {
  @Prop() word!: string;
  @Prop({ default: false }) isTutorial!: boolean;
  @Prop({ default: false }) answerLocked?: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    if (this.answerLocked) return;
    clickSound.play();
    if (this.isTutorial) {
      // setTimeout(async () => {
      //   this.showOrangeBorder = false;
      // }, 1000);
    } else {
      this.showOrangeBorder = true;
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 300);
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn.v-size--default {
  border: 5px solid transparent;
  text-transform: none;
  width: 100%;
  height: 48px !important;
  color: $answer-color;
  font-weight: 400;
}

@media only screen and (min-width: 600px) {
  .v-btn.v-size--default {
    width: 100%;
    height: 42px !important;
  }
}

@media only screen and (min-width: 960px) {
  .v-btn.v-size--default {
    width: 80%;
    height: 46px !important;
  }
}

@media only screen and (min-width: 1264px) {
  .v-btn.v-size--default {
    width: 80%;
    height: 50px !important;
  }
}

@media only screen and (min-width: 1904px) {
  .v-btn.v-size--default {
    width: 80%;
    height: 48px !important;
  }
}
</style>
