<template>
  <v-col
    class="option-container d-flex flex-column justify-center align-center"
    cols="12"
    sm="10"
    md="7"
    lg="5"
    xl="5"
  >
    <TestOptionButton
      v-for="option in testOptions"
      :key="option.id"
      :item="option"
    />
  </v-col>
</template>

<script lang="ts">
import RiddleModule from '@/store/modules/RiddleModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import TestOptionButton from './TestOptionButton.vue';
import { TestOptionItem } from '../../../utils/helpers';

@Component({ components: { TestOptionButton } })
export default class TestOptionContainer extends Vue {
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  testOptions: TestOptionItem[] = [
    {
      id: 1,
      title: 'Buchstabenjagd',
      img: '@/assets/images/flower.png',
    },
    {
      id: 2,
      title: 'Fantasiewörter',
      img: '@/assets/images/flower.png',
    },
    {
      id: 3,
      title: 'Silbensuche',
      img: '@/assets/images/flower.png',
    },
    {
      id: 4,
      title: 'Wörter finden',
      img: '@/assets/images/flower.png',
    },
    {
      id: 5,
      title: 'Wörterrätsel',
      img: '@/assets/images/flower.png',
    },
  ];
}
</script>

<style scoped lang="scss">
.option-container {
  // max-height: 70vh;
  margin-top: 2%;
}

// @media screen and (min-width: 600px) {
//   .option-container {
//     max-height: 65%;
//     margin-top: 2%;
//   }
// }
// @media screen and (min-width: 960px) {
//   .option-container {
//     max-height: 70%;
//     margin-top: 2%;
//   }
// }
// @media screen and (min-width: 1264px) {
//   .option-container {
//     max-height: 75%;
//     margin-top: 2%;
//   }
// }
// @media screen and (min-width: 1904px) {
//   .option-container {
//     max-height: 65%;
//     margin-top: 3%;
//   }
// }
</style>
