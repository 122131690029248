<template>
  <v-col
    cols="12"
    class="welcome-leo d-flex flex-column justify-center align-center center-component"
    align="center"
  >
    <p v-show="hasImage" class="text-h2 myHeadline white--text">
      {{ $t('super') }}
    </p>
    <v-col cols="6" sm="3" md="3" lg="3" xl="3">
      <v-progress-circular
        v-if="!hasImage"
        class="center-component"
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <v-img
        v-show="hasImage"
        @load="setHasImage"
        :src="leo"
        eager
        contain
        max-height="550"
      />
    </v-col>
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
@Component
export default class CongratulationsContainer extends Vue {
  hasImage = false;

  setHasImage(): void {
    this.hasImage = true;
  }

  get leo(): string {
    return require('@/assets/images/lion.png');
  }
}
</script>

<style scoped lang="scss">
.welcome-leo {
  width: 100%;
  height: 50%;
  margin-top: 16px;
}
</style>
