<template>
  <v-col
    class="word-image-container d-flex justify-center pa-0"
    cols="7"
    sm="7"
    md="6"
    lg="6"
    xl="6"
  >
    <v-img
      class="word-image"
      contain
      :src="image"
      :alt="alt"
      max-height="600"
      max-width="400"
      eager
      @load="loaded"
      @loadstart="loadStart"
    />
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import WordsModule from '../../../store/modules/WordsModule';

@Component
export default class FindWordsImage extends Vue {
  @Prop() image!: string;
  @Prop() alt!: string;

  wordsModule: WordsModule = getModule(WordsModule, this.$store);

  @Emit()
  loaded(): void {
    return;
  }

  @Emit()
  loadStart(): void {
    return;
  }
}
</script>

<style scoped lang="scss">
.word-image-container {
  background-color: white;
  border-radius: 8px;
  max-width: 400px;
  max-height: 600px;
}
.word-image {
  border-radius: 8px;
}
</style>
