<template>
  <v-col
    cols="3"
    class="img-container ma-2 d-flex justify-center"
    v-if="active"
  >
    <v-img
      :src="`${activeImage}.${format}`"
      class="active fantasy-option ma-2"
      @click.native="showBorder"
      :class="{ showOrangeBorder: showOrangeBorder }"
      eager
    />
    <v-img
      v-if="showBubble"
      :src="bubble"
      contain
      eager
      class="fantasy-option bubble"
    />
  </v-col>
  <v-col cols="3" class="img-container ma-2 d-flex justify-center" v-else>
    <v-img :src="`${image}.${format}`" class="fantasy-option ma-2" eager />
  </v-col>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class FantasyWordsAnswerOption extends Vue {
  @Prop() image!: string;
  @Prop() activeImage!: string;
  @Prop() format!: string;
  @Prop({ default: false }) showBubble?: boolean;
  @Prop({ default: false }) isTutorial?: boolean;
  @Prop({ default: false }) answerLocked?: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    if (this.answerLocked) return;
    clickSound.play();
    this.showOrangeBorder = true;
    if (this.isTutorial) {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 2000);
    } else {
      setTimeout(() => {
        this.showOrangeBorder = false;
      }, 1000);
    }
  }
  get active(): boolean {
    return this.activeImage !== '';
  }

  get bubble(): string {
    return require('@/assets/images/bubble.png');
  }
}
</script>

<style scoped lang="scss">
.fantasy-option {
  box-shadow: 0 0 0 6px transparent;
  border-radius: 20px;
  z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
  .fantasy-option.active {
    &:hover {
      // box-shadow: 0 0 0 6px orange;
      border-radius: 20px;
      cursor: pointer !important;
    }
  }
}

.img-container {
  object-fit: cover;
  position: relative;
}

.v-image {
  width: 170px !important;
}

.bubble {
  z-index: 3;
  position: absolute;
  right: 0;
  top: 0;
  height: 30% !important;
  width: 40% !important;
}

@media only screen and (max-width: 752px) {
  .v-image {
    width: 20vw !important;
  }
}

@media only screen and (max-width: 580px) {
  .v-image {
    width: 25vw !important;
    // width: 120px !important;
  }
}
</style>
