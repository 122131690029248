<template>
  <v-btn
    class="words-answer mt-0 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-2 ml-0 mb-2 mb-sm-2 mb-md-4 mb-lg-2 mb-xl-2 mr-2 mr-sm-6 mr-md-6 mr-lg-6 mr-xl-6 text-body-2 text-sm-h6 text-md-h5 text-lg-h5 text-xl-h4 unselectable norddruck"
    elevation="8"
    raised
  >
    {{ word }}
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class RiddleAnswerOption extends Vue {
  @Prop() word!: string;
}
</script>

<style scoped lang="scss">
.v-btn.v-size--default {
  border: 5px solid transparent;
  text-transform: none;
  width: 100%;
  flex: 1 1 auto;
  height: $riddle_xs !important;
  max-height: $riddle_xs !important;
  color: $answer-color;
  font-weight: 400;
}

@media (hover: hover) and (pointer: fine) {
  .v-btn.v-size--default:hover {
    // border: 5px solid orange;
    // transform: scale(1);
  }
}

@media only screen and (min-width: 600px) {
  .v-btn.v-size--default {
    width: 100%;
    height: $riddle_sm !important;
    max-height: $riddle_sm !important;
  }
}

@media only screen and (min-width: 960px) {
  .v-btn.v-size--default {
    width: 100%;
    height: $riddle_md !important;
    max-height: $riddle_md !important;
  }
}

@media only screen and (min-width: 1264px) {
  .v-btn.v-size--default {
    width: 100%;
    height: $riddle_lg !important;
    max-height: $riddle_lg !important;
  }
}

@media only screen and (min-width: 1904px) {
  .v-btn.v-size--default {
    width: 100%;
    height: $riddle_xl !important;
    max-height: $riddle_xl !important;
  }
}
</style>
