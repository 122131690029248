<template>
  <v-col
    cols="12"
    class="option__column d-flex flex-column justify-center align-center pa-0 pa-sm-0 pa-md-1 pa-lg-1 pa-xl-1 my-2"
  >
    <v-btn
      class="option--button"
      elevation="2"
      raised
      @click="playClick"
      :disabled="buttonDisabled"
    >
      <v-row class="option__content">
        <v-col
          cols="12"
          class="d-flex align-center"
          :class="buttonDisabled ? 'justify-start' : 'justify-center'"
        >
          <v-col v-if="buttonDisabled" cols="'3'" class="" ma-0 pa-0></v-col>
          <v-col :cols="buttonDisabled ? '6' : '12'">
            <p
              class="option__text my-0 text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h3 unselectable myButton pt-1"
            >
              {{ item.title }}
            </p>
          </v-col>
          <v-col
            v-if="buttonDisabled"
            cols="3"
            class="d-flex justify-end align-center"
          >
            <LeoImage v-if="hasDoneLetters && isLetters" />
            <LeoImage v-else-if="hasDoneFantasy && isFantasy" />
            <LeoImage v-else-if="hasDoneSyllables && isSyllables" />
            <LeoImage v-else-if="hasDoneWords && isWords" />
            <LeoImage v-else-if="hasDoneRiddle && isRiddle" />
          </v-col>
        </v-col>
      </v-row>
    </v-btn>
  </v-col>
</template>

<script lang="ts">
import router from '@/router';
import FantasyModule from '@/store/modules/FantasyModule';
import LettersModule from '@/store/modules/LettersModule';
import RiddleModule from '@/store/modules/RiddleModule';
import SyllableModule from '@/store/modules/SyllableModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import SchoolModule from '@/store/modules/SchoolModule';
import { getSchoolPrefix } from '@/utils/common';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { Test } from '../../../api/types/api';
import { Testname, TestOptionItem } from '../../../utils/helpers';
import LeoImage from './LeoImage.vue';
import { logToServer } from '@/utils/serverLog';

@Component({ components: { LeoImage } })
export default class TestOption extends Vue {
  @Prop() readonly item!: TestOptionItem;

  lettersModule: LettersModule = getModule(LettersModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  riddleModule: RiddleModule = getModule(RiddleModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  syllableModule: SyllableModule = getModule(SyllableModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);

  hasDoneLetters = false;
  isLetters = this.item.title === Testname[Test.letters];
  hasDoneFantasy = false;
  isFantasy = this.item.title === Testname[Test.fantasy];
  hasDoneSyllables = false;
  isSyllables = this.item.title === Testname[Test.syllables];
  hasDoneWords = false;
  isWords = this.item.title === Testname[Test.words];
  hasDoneRiddle = false;
  isRiddle = this.item.title === Testname[Test.riddle];

  get hasFinishedATest(): boolean {
    return (
      (this.hasDoneLetters && this.isLetters) ||
      (this.hasDoneFantasy && this.isFantasy) ||
      (this.hasDoneSyllables && this.isSyllables) ||
      (this.hasDoneWords && this.isWords) ||
      (this.hasDoneRiddle && this.isRiddle)
    );
  }

  get buttonDisabled(): boolean {
    if (
      process.env.VUE_APP_TEST_UNLOCKED === 'true' ||
      this.schoolModule.isTutorialOnlyVersion
    ) {
      return false;
    }

    return (
      (this.hasDoneLetters && this.isLetters) ||
      this.disableFantasy ||
      this.disableSyllables ||
      this.disableWords ||
      this.disableRiddle
    );
  }

  get disableFantasy(): boolean {
    return this.isFantasy && (!this.hasDoneLetters || this.hasDoneFantasy);
  }

  get disableSyllables(): boolean {
    return this.isSyllables && (!this.hasDoneFantasy || this.hasDoneSyllables);
  }

  get disableWords(): boolean {
    return this.isWords && (!this.hasDoneSyllables || this.hasDoneWords);
  }

  get disableRiddle(): boolean {
    return this.isRiddle && (!this.hasDoneWords || this.hasDoneRiddle);
  }

  created(): void {
    // TODO delete only for debugging!
    // this.hasDoneLetters = true;
    // this.hasDoneWords = true;
    // this.hasDoneFantasy = true;
    // this.hasDoneRiddle = true;
    // this.hasDoneSyllables = true;
    this.hasDoneLetters = this.lettersModule.isDone;
    this.hasDoneWords = this.wordsModule.isDone;
    this.hasDoneFantasy = this.fantasyModule.isDone;
    this.hasDoneRiddle = this.riddleModule.isDone;
    this.hasDoneSyllables = this.syllableModule.isDone;
  }

  getCorrectPath(value: string): string {
    let path = '';
    const prefix = getSchoolPrefix();
    switch (value) {
      case Testname[Test.letters]:
        path = `/${prefix}/tests/${this.lettersModule.currentForm}/${Testname[
          Test.letters
        ].toLowerCase()}`;
        break;
      case Testname[Test.fantasy]:
        path = `/${prefix}/tests/${this.fantasyModule.currentForm}/${Testname[
          Test.fantasy
        ]
          .toLowerCase()
          .replace('ö', 'oe')}`;
        break;
      case Testname[Test.syllables]:
        path = `/${prefix}/tests/${this.syllableModule.currentForm}/${Testname[
          Test.syllables
        ].toLowerCase()}`;
        break;
      case Testname[Test.words]:
        path = `/${prefix}/tests/${this.wordsModule.currentForm}/${Testname[
          Test.words
        ]
          .toLowerCase()
          .replace(' ', '-')
          .replace('ö', 'oe')}`;
        break;
      case Testname[Test.riddle]:
        path = `/${prefix}/tests/${this.riddleModule.currentForm}/${Testname[
          Test.riddle
        ]
          .toLowerCase()
          .replace('ä', 'ae')
          .replace('ö', 'oe')}`;
        break;
      default:
        break;
    }
    return path;
  }

  playClick(): void {
    logToServer(
      'info',
      `starting test ${this.item.title}, version = ${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_VCS_HASH})`,
      this.userModule.user,
    );
    this.userModule.stopActiveAudio();
    let path = this.getCorrectPath(this.item.title);
    // INFO play "Leo zeigt dir, wie es geht..."
    const audio = new Audio('/audio/show.mp3');
    this.userModule.setActiveAudio(audio);
    audio.play();

    setTimeout(async () => {
      router.push({ path: path });
    }, 500);
  }
}
</script>

<style scoped lang="scss">
.option {
  &__column {
    height: 18%;
  }

  &--button {
    border-radius: 20px;
    // height: 3.5rem !important;
    height: 75px !important;
    width: 100%;
    background-color: $yellow !important;
  }

  &__text {
    text-transform: none;
    font-weight: 500;
    color: $dark-blue;
  }

  &__content {
    width: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .option--button:hover {
    transform: scale(1.05);
  }
}

.theme--light.v-btn.v-btn--disabled {
  background-color: rgba(255, 255, 255, 0.199) !important;
}

// @media screen and (min-width: 600px) {
//   .option--button {
//     height: 70px !important;
//     width: 100%;
//   }
// }
// @media screen and (min-width: 960px) {
//   .option--button {
//     height: 75px !important;
//   }
// }

@media screen and (min-width: 1264px) {
  .option--button {
    height: 80px !important;
  }
}
@media screen and (min-width: 1904px) {
  .option--button {
    height: 90px !important;
  }
}
</style>
