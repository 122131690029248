<template>
  <v-col class="d-flex justify-center pa-0 ma-0">
    <v-col
      v-if="!isEnd"
      class="words-answer d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row justify-center align-center"
      cols="12"
      sm="10"
      md="8"
      lg="7"
      xl="6"
    >
      <TutorialEndEmoji
        v-if="!started"
        :text="$t('tests.go-start')"
        :disabled="disableButtons"
        @start="
          started = true;
          startInitial();
        "
      />

      <!-- <v-col class="hide">
        <img
          v-for="(i, idx) in images"
          :src="`/images/find-words/${i}.${format}`"
          :key="idx"
        />
      </v-col> -->

      <template v-if="started">
        <FindWordsImage
          class="mt-2"
          :image="`/images/find-words/${currentQuestion.solution}.${format}`"
          :placeholder="null"
          :alt="currentQuestion.solution"
          @loaded="setHasImage"
          @load-start="resetImage"
        />
        <v-col
          v-show="hasImage"
          cols="6"
          class="d-flex flex-column justify-center align-center pr-4 pr-sm-7 pr-md-7 pr-lg-7 pr-xl-6 mt-2"
        >
          <FindWordsAnswerOption
            v-for="(option, idx) in currentQuestion.options"
            :key="`option-${idx}`"
            :word="option"
            @click.native="answerQuestion(option)"
            :answerLocked="answerLocked"
          />
        </v-col>
      </template>
    </v-col>
    <audio :src="'/audio/toll.mp3'" ref="testEndSound"></audio>
    <TestEndEmoji
      v-if="isEnd"
      :saving="loadingSave"
      :save-success="saveSuccess"
      :reached-max-retries="reachedMaxRetries"
      :module="wordsModule"
    />
  </v-col>
</template>

<script lang="ts">
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { WordsQuestionType } from '@/types';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import TestEndEmoji from '../../base/TestEndEmoji.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import FindWordsAnswerOption from './FindWordsAnswerOption.vue';
import FindWordsImage from './FindWordsImage.vue';
import RetryMixin from '../RetryMixin';

@Component({
  components: {
    FindWordsAnswerOption,
    FindWordsImage,
    TestEndEmoji,
    TutorialEndEmoji,
  },
})
export default class FindWordsContainer extends RetryMixin {
  wordsModule: WordsModule = getModule(WordsModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  started = false;
  @Ref() testEndSound!: HTMLAudioElement;

  start = 0;
  stop = 0;
  disableButtons = true;
  hasImage = false;
  answerLocked = false;
  // images =
  //   this.wordsModule.chosenForm === 'a' ? findWordsImages_a : findWordsImages_b;

  get currentQuestion(): WordsQuestionType {
    return this.wordsModule.currentQuestion;
  }

  get isEnd(): boolean {
    return (
      this.wordsModule.overallQuestions ===
      this.wordsModule.result.questionsAnswered
    );
  }

  get format(): string {
    return this.wordsModule.imageFormat;
  }

  mounted(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 6500);
  }

  startInitial(): void {
    this.start = performance.now();
  }

  setHasImage(): void {
    this.hasImage = true;
    // INFO unlock answer here when image is loaded...
    this.answerLocked = false;
    // INFO start timer here when image is loaded...
    this.start = performance.now();
  }

  resetImage(): void {
    this.hasImage = false;
  }

  async updated(): Promise<void> {
    if (
      this.isEnd &&
      !this.loadingSave &&
      !this.saveSuccess &&
      this.currentRetries < this.MAX_RETRIES
    ) {
      try {
        this.loadingSave = true;
        this.currentRetries++;

        const res = await this.wordsModule.saveStatus(
          this.userModule.currentUser._id,
        );

        if (res?.status === 200) {
          this.saveSuccess = true;
          this.wordsModule.finishedAndSavedTest();
        }
      } catch (error: any) {
        this.wordsModule.resetDone();
        console.error(
          '[WORDS] error saving status',
          error?.code,
          error?.message,
          error?.name,
          error?.response?.data?.error,
          error?.response?.data?.message,
          error?.response?.data?.statusCode,
        );
      } finally {
        this.loadingSave = false;
      }
    }
  }

  answerQuestion(answer: string): void {
    if (this.answerLocked) return;
    this.answerLocked = true;
    this.stop = performance.now();
    const solution = this.currentQuestion.solution;

    const time = this.stop - this.start;
    setTimeout(
      () =>
        this.wordsModule.addAnswer({
          solution: solution,
          answer: answer,
          time: Math.round(time),
          isCorrect: answer === solution,
        }),
      300,
    );
  }
}
</script>

<style scoped lang="scss">
.words-answer {
  width: 100%;
  max-height: 70%;
  margin-bottom: auto;
}

.top-margin {
  margin-top: 8%;
}

.hide {
  display: none;
}
</style>
