<template>
  <v-row
    class="fantasy__question justify-center align-end ma-0 pa-2 flex-shrink-1 mb-16"
  >
    <p
      class="text-h5 text-sm-h4 text-md-h4 text-lg-h4 text-xl-h4 ma-0 pa-4 unselectable norddruck"
    >
      {{ question }}
    </p>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class FantasyWordQuestion extends Vue {
  @Prop() question!: string;
}
</script>

<style scoped lang="scss">
.fantasy {
  &__question {
    width: 100%;
    border-radius: 15px;

    p {
      width: 80%;
      border-radius: 15px;
      background: white;
      text-align: center;
    }
  }
}
</style>
