<template>
  <v-row class="riddle-question justify-center align-end ma-0 mx-8 pa-2">
    <p
      class="text-body-1 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h3 white--text ma-0 mt-0 unselectable norddruck"
    >
      {{ question }}
    </p>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component
export default class RiddleQuestion extends Vue {
  @Prop() question!: string;
}
</script>

<style scoped lang="scss">
.riddle-question {
  max-height: 15%;
  width: 90%;
  // width: 80%;
  border-bottom: 4px white solid;
}

// @media only screen and (max-width: 650px) {
//   .riddle-question {
//     width: 90%;
//   }
// }
</style>
