<template>
  <v-col
    cols="11"
    md="6"
    lg="4"
    xl="4"
    class="studentLogin_outer-wrapper pa-0 ma-0 align-self-center d-flex justify-center"
  >
    <v-col
      cols="12"
      class="studentLogin d-flex flex-column align-self-end justify-start top-margin pa-0"
    >
      <v-progress-circular
        v-if="!hasImage"
        class="center-component"
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
      <v-card
        v-show="hasImage"
        elevation="5"
        class="studentLogin__container justify-center align-center pa-0"
      >
        <v-col cols="12" class="mt-0" align="center">
          <v-form @submit.prevent="login" class="mb-6">
            <v-col cols="2" class="pa-0">
              <v-img contain :src="leo" eager @load="setHasImage"> </v-img>
            </v-col>
            <v-col
              cols="12"
              class="login-form justify-center align-center pa-0"
            >
              <v-col class="pb-0" cols="10">
                <v-text-field
                  :value="username.toLowerCase()"
                  :error-messages="usernameErrors"
                  label="Benutzername"
                  required
                  outlined
                  background-color="white"
                  @input="
                    username = $event.toLowerCase().replace(/\s/g, '');
                    $v.username.$touch();
                  "
                  id="username"
                  @blur="$v.username.$touch()"
                  color="black"
                  class="student-login"
                  autocomplete="username"
                ></v-text-field>
              </v-col>

              <v-col class="pb-0" cols="10">
                <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  label="Passwort"
                  id="password"
                  required
                  outlined
                  background-color="white"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  color="black"
                  class="student-login"
                  autocomplete="new-password"
                ></v-text-field>
              </v-col>

              <v-col class="pb-0" cols="10">
                <v-select
                  v-model="grade"
                  :items="gradeItems"
                  :error-messages="gradeErrors"
                  label="Klasse"
                  id="grade"
                  required
                  @input="$v.grade.$touch()"
                  @blur="$v.grade.$touch()"
                  color="black"
                  outlined
                  background-color="white"
                  class="student-login"
                ></v-select>
              </v-col>
            </v-col>

            <p
              v-if="licenseExpired"
              class="ma-0 text-body-1 norddruck text-uppercase"
            >
              {{ $t('license-expired') }}
            </p>
            <p
              v-else-if="hasNoValidTestsLeft"
              class="ma-0 text-body-1 norddruck text-uppercase"
            >
              {{ $t('no-valid-tests') }}
            </p>
            <v-btn
              v-else
              rounded
              color="#ead148"
              class="start-btn ma-1 mt-1"
              type="submit"
              id="login-btn"
              :loading="loading"
              :disabled="licenseExpired || hasNoValidTestsLeft"
            >
              <p
                class="ma-0 text-h5 text-sm-h5 text-md-h5 text-lg-h5 text-xl-h5 norddruck"
              >
                {{ $t('login') }}
              </p>
            </v-btn>
          </v-form>
          <v-btn
            v-if="!isParents"
            icon
            @click="goToAdminLogin"
            class="adminLoginButton"
          >
            <p class="ma-0 mt-1">
              {{ isTherapy ? $t('therapy-login') : $t('admin-login') }}
            </p>
            <v-icon right>mdi-login-variant</v-icon>
          </v-btn>
        </v-col>
      </v-card>
      <v-col class="farLeft">
        <v-img
          :src="`${testImage}.webp`"
          max-height="1"
          max-width="1"
          eager
          @error="setCorrectImageFormat"
        />
      </v-col>
      <LoginInfoBar />
    </v-col>
  </v-col>
</template>

<script lang="ts">
import { Client } from '@/api/types';
import FantasyModule from '@/store/modules/FantasyModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import WordsModule from '@/store/modules/WordsModule';
import { getSchoolPrefix } from '@/utils/common';
import platform from 'platform';
import Vue from 'vue';
import Component from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import LoginInfoBar from '../../base/LoginInfoBar.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    username: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(3) },
    grade: { required },
  },
  components: { LoginInfoBar },
})
export default class StudentLoginContainer extends Vue {
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  wordsModule: WordsModule = getModule(WordsModule, this.$store);

  isDisabled = false;
  username = '';
  password = '';
  grade = null;
  show = false;
  loading = false;

  testImage = '/images/fantasy/Max';
  hasImage = false;

  setHasImage(): void {
    this.hasImage = true;
  }

  defaultImages = [
    require('@/assets/images/lion_small.png'),
    require('@/assets/images/lion.png'),
    require('@/assets/images/palm_leaf.png'),
    require('@/assets/images/bubble.png'),
  ];

  setCorrectImageFormat(): void {
    this.fantasyModule.setImageFormat();
    this.wordsModule.setImageFormat();
  }

  get leo(): string {
    return require('@/assets/images/lion_small.png');
  }

  get licenseExpired(): boolean {
    return this.schoolModule.licenseExpired;
  }

  get hasNoValidTestsLeft(): boolean {
    return this.isTherapy ? this.schoolModule.hasNoValidTestsLeft : false;
  }

  get schoolId(): string {
    return this.schoolModule.school.schoolId!;
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParents(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  mounted() {
    const select = document.querySelector('#grade');
    select?.setAttribute('readonly', 'readonly');
  }

  async login(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;

        // 1 - get clienttype
        const clientType = this.schoolModule.clientType;

        // 2 - choose correct school suffix according to client type
        const schoolSuffix =
          clientType === Client.school
            ? `${this.grade}_${this.schoolId}`
            : `_${this.schoolId}`;

        const studentUsername =
          this.username.toLowerCase().trim() + schoolSuffix.toLowerCase();
        await this.userModule.login({
          username: studentUsername,
          password: this.password.trim(),
        });
        await this.userModule.whoAmI(this.schoolId);

        if (this.isTherapy || this.isParents) {
          const studentGrade = this.userModule.currentUser.grade;
          if (studentGrade !== this.grade) {
            this.userModule.logout();
            throw new Error('grades do not match');
          }
        }
        //TODO add platform???
        try {
          const info = platform.parse(navigator.userAgent);
          await this.userModule.updateUser({
            data: {
              platform: {
                name: info.name ?? '',
                version: info.version ?? '',
                layout: info.layout ?? '',
                os: info.os?.toString() ?? '',
                description: info.description ?? '',
                ua: info.ua ?? '',
                product: info.product ?? '',
                manufacturer: info.manufacturer ?? '',
              },
            },
            id: this.userModule.currentUser._id,
          });
        } catch (error) {
          console.log('no user agent');
        }

        if (this.isParents) {
          this.$router.push({
            name: 'parentInfo',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        } else {
          this.$router.push({
            name: 'start',
            replace: true,
            params: { schoolName: getSchoolPrefix() },
          });
        }
      } catch (error: any) {
        if (error?.message === 'grades do not match') {
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.login.error.grade').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$t('snackbar.login.error.info').toString(),
            isSuccess: false,
          });
        }
      }
      this.loading = false;
    }
  }

  clear(): void {
    this.$v.$reset();
    this.username = '';
    this.password = '';
    this.grade = null;
  }

  goToAdminLogin(): void {
    this.$router.push({
      name: 'school',
      replace: true,
      params: { schoolName: getSchoolPrefix() },
    });
  }

  get gradeItems(): string[] {
    return this.schoolModule.classes;
  }

  get usernameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.username.$dirty) return errors;
    !this.$v.username.minLength &&
      errors.push('Dein Benutzername ist mind. 3 Buchstaben lang!');
    !this.$v.username.required && errors.push('Wie lautet dein Benutzername?');
    return errors;
  }

  get passwordErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.password.$dirty) return errors;
    !this.$v.password.minLength &&
      errors.push('Dein Passwort hat mind. 3 Buchstaben!');
    !this.$v.password.required && errors.push('Bitte gib dein Passwort ein!');
    return errors;
  }

  get gradeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.grade.$dirty) return errors;
    !this.$v.grade.required && errors.push('In welche Klasse gehst du?');
    return errors;
  }
}
</script>

<style scoped lang="scss">
.student-login .v-messages__message {
  font-size: 15px !important;
}

.start-btn {
  width: 70%;
  height: 53px !important;

  p {
    color: $dark-blue !important;
  }
}

.login-form .v-messages__message {
  color: white !important;
  caret-color: white !important;
}

.studentLogin {
  &__container {
    background-color: #eeeeee !important;
    border-radius: 15px !important;
  }
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 60%;
    height: 42px !important;
  }

  // .studentLogin__container {
  //   width: 70%;
  // }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 60%;
    height: 48px !important;
  }

  // .studentLogin__container {
  //   width: 60%;
  // }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 60%;
    height: 50px !important;
  }

  // .studentLogin__container {
  //   width: 50%;
  // }
}

.top-margin {
  margin-top: 0%;
}

.farLeft {
  position: absolute;
  translate: -120vh -200vw;
}

.adminLoginButton {
  color: $dark-blue !important;
}
</style>
