<template>
  <v-btn
    class="letters-answer ma-2 unselectable"
    elevation="2"
    raised
    :class="{ disabled: isPlaying, showOrangeBorder: showOrangeBorder }"
    v-ripple="{ class: 'transparent' }"
    @click.native="showBorder"
  >
    {{ letter }}
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickSound } from '../../../utils/common';

@Component
export default class LettersTestAnswerOption extends Vue {
  @Prop() letter!: string;
  @Prop() isPlaying!: boolean;
  @Prop({ default: false }) isTutorial!: boolean;

  showOrangeBorder = false;

  showBorder(): void {
    clickSound.play();
    this.showOrangeBorder = true;
    if (this.isTutorial) {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 300);
    } else {
      setTimeout(async () => {
        this.showOrangeBorder = false;
      }, 100);
    }
  }
}
</script>

<style scoped lang="scss">
.letters-answer {
  min-width: 130px !important;
  min-height: 100px;
  width: 10vw;
  height: 7vw !important;
  text-transform: none;

  &.disabled {
    color: gray;
    &::before {
      background-color: transparent;
    }
  }

  &.v-btn.v-size--default {
    font-size: 2.5rem !important;
    font-weight: 400;
    color: $answer-color;
  }
}

@media (hover: hover) and (pointer: fine) {
  .letters-answer.disabled:hover {
    border: 5px solid transparent;
  }
}
</style>
