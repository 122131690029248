<template>
  <v-col
    cols="12"
    class="welcome-leo d-flex justify-center align-center"
    align="center"
  >
    <v-col cols="12" class="d-flex flex-column align-center justify-center">
      <p class="text-h2 myHeadline white--text">{{ $t('not-found') }}</p>
      <v-col cols="6" sm="3" md="3" lg="2" xl="3">
        <v-img :src="leo" eager contain />
      </v-col>

      <v-btn
        large
        rounded
        color="#ead148"
        @click="goBack"
        class="start-btn ma-8"
      >
        <p
          class="ma-0 text-h5 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h3 pt-1"
        >
          {{ $t('back') }}
        </p>
      </v-btn>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import router from '@/router';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class NotFoundContainer extends Vue {
  get leo(): string {
    return require('@/assets/images/lion.png');
  }

  goBack(): void {
    router.back();
  }
}
</script>

<style scoped lang="scss">
.welcome-leo {
  width: 100%;
  height: 50%;
  margin-top: 16px;
}

.start-btn {
  width: 45%;
  height: 55px !important;

  p {
    color: $dark-blue !important;
    font-family: $font-family, sans serif !important;
  }
}

@media screen and (min-width: 600px) {
  .start-btn {
    width: 50%;
    height: 48px !important;
  }
}

@media screen and (min-width: 960px) {
  .start-btn {
    width: 30%;
    height: 48px !important;
  }
}
@media screen and (min-width: 1264px) {
  .start-btn {
    width: 35%;
    height: 60px !important;
  }
}
</style>
